import React, { useState } from 'react';
import { Button, TextField, Grid, Typography, FormControlLabel, Checkbox } from '@mui/material';
import sitelist from '../json/site-list.json';
import { isValidDate } from './validate';
import useFocus from './useFocus';

function ListingEntry(props) {
	const { updateListEntryData, updateListEntryStatus, userEntryData } = props;
	const defaultObsDate = userEntryData.obsdate ? (userEntryData.obsdate.getFullYear() + "-" + (userEntryData.obsdate.getMonth()+1) + "-" + userEntryData.obsdate.getDate()) : "";
	const [ coopid, setCoopid ] = useState(userEntryData.coopid || "");
	const [ obsdate, setObsdate ] = useState(defaultObsDate);
	const [ showError, setShowError ] = useState(false);
	const [ weekChecked, setWeekChecked ] = useState(userEntryData.weekChecked || false);

	const [ coopidToolRef, setCoopidFocus ] = useFocus();
	const [ obsdateToolRef, setObsdateFocus ] = useFocus();

	const handleIdChange = (event) => {
		const coopid = event.target.value.toUpperCase();
		setCoopid(coopid);
		setShowError(false); //clear error message when user starts to enter new value
	};

	const handleDateChange = (event) => {
		const obsdate = event.target.value;
		setObsdate(obsdate);
		setShowError(false); //clear error message when user starts to enter new value
	};

	const handleCheckboxChange = (event) => {
		setWeekChecked(event.target.checked);
	};

	const handleClick = () => {
		const ids = sitelist[coopid] || [];
		if (!ids.length && coopid !== '*') {
			setShowError("This is not a valid cooperator id; please enter a different id.");
			setCoopid("");
			setCoopidFocus();
		} else if (!isValidDate(obsdate)) {
			setShowError("Invalid date");
			setObsdate("");
			setObsdateFocus();
		} else {
			setShowError(false);
			updateListEntryData(coopid, obsdate, weekChecked);
			updateListEntryStatus(false);
		};
	};

	return (
		<>

				<form noValidate autoComplete="off">
					<Grid container direction="column" alignItems="center">
						<Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={3}>
							<Grid item>
								<TextField 
									required 
									autoFocus
									variant="outlined"
									value={coopid} 
									name="coopid"
									label="Cooperator ID" 
									onChange={handleIdChange}
									inputRef={coopidToolRef}
									onFocus={(event) => event.target.select()}
									helperText="Enter * for all"
								/>
							</Grid>
							<Grid item>
								<TextField 
									required 
									variant="outlined"
									value={obsdate} 
									name="obsdate"
									label="Observation date" 
									onChange={handleDateChange}
									inputRef={obsdateToolRef}
									onFocus={(event) => event.target.select()}
									placeholder="yyyy-mm-dd"
								/>
							</Grid>
						</Grid>

						<Grid item>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={weekChecked}
										onChange={handleCheckboxChange}
									/>
								}
								label="Include all reports for week"
							/>
						</Grid>

						<Grid item>
							{showError &&
								<Typography variant="body2" color="error" mt={1.5}>
									{showError}
								</Typography>
							}
						</Grid>
						
						<Grid item>
							<Button variant="contained" color="primary" onClick={handleClick}>
								Continue
							</Button>
						</Grid>
					</Grid>
				</form>

		</>
	);
}
export default ListingEntry;
