import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme
const theme = createTheme({
  palette: {
    mode: 'light',
    secondary: {
      main: '#b5ecfb',
      light: '#61dafb',
      dark: '#21a1c4',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#282c34',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          textAlign: 'center',
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '20px 10px',
          backgroundColor: '#fff', // 5d737e
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          margin: '1em',
        },
        sizeSmall: {
          margin: '0',
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          padding: '6px 6px'
        },
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      }
    },
  },
});
export default theme;