// Validate date string
export const isValidDate = (text) => {
    const current_year = new Date().getFullYear();
    try {
        const ymd = text.split("-");
        if (ymd.length !== 3 ||
            ymd[0] < 2000 || ymd[0] > current_year ||
            ymd[1] < 1 || ymd[1] > 12 ||
            ymd[2] < 1 || ymd[2] > 31) return false;
        const dt = new Date(ymd[0],parseInt(ymd[1])-1,ymd[2]);
        return dt instanceof Date && isFinite(dt);
    } catch {
        return false;
    }
};

// Validate validity of formatted date/time string
export const isValidDatestr = (text) => {
    try {
        const dt = new Date(text);
        return dt instanceof Date && isFinite(dt);
    } catch {
        return false;
    }
};

// Validate entered data
export const validateEnteredData = (input) => {
    const current_year = new Date().getFullYear();
    let errors = {};
    let warnings = "";
    let errorFields = {};
    Object.keys(input).forEach((id) => {
        if (input[id].snwd.length || input[id].sweq.length || input[id].notes.length) {
            const errorsForId = [];
            const errorFieldsForId = [];
            if ((isNaN(input[id].year) || Number.parseInt(input[id].year, 10) < 2000 || Number.parseInt(input[id].year,10) > current_year) && input[id].year !== "") {
                errorsForId.push("Year must be between 2000 and the current year.");
                errorFieldsForId.push("year");
            };
            if ((isNaN(input[id].month) || ![1,2,3,4,5,10,11,12].includes(Number.parseInt(input[id].month, 10))) && input[id].month !== "") {
                errorsForId.push("Month must be between 1 and 5 or 10 and 12.");
                errorFieldsForId.push("month");
            };
            if ((isNaN(input[id].day) || Number.parseInt(input[id].day, 10) < 1 || Number.parseInt(input[id].day,10) > 31) && input[id].day !== "") {
                errorsForId.push("Day must be between 1 and 31.");
                errorFieldsForId.push("day");
            };
            if ((isNaN(input[id].hour) || Number.parseInt(input[id].hour, 10) < 0 || Number.parseInt(input[id].hour,10) > 24) && input[id].hour !== "") {
                errorsForId.push("Hour must be between 0 and 24.");
                errorFieldsForId.push("hour");
            };
            if ((isNaN(input[id].snwd) && !['T','M'].includes(input[id].snwd)) && input[id].snwd !== "") {
                errorsForId.push("Snow depth must be T or a number.");
                errorFieldsForId.push("snwd");
            };
            if ((isNaN(input[id].sweq) && !['T','M'].includes(input[id].sweq)) && input[id].sweq !== "") {
                errorsForId.push("Snow water equivelant must be T or a number.");
                errorFieldsForId.push("sweq");
            } else if (input[id].snwd !== "" && input[id].sweq !== "" && input[id].snwd !== "M" && input[id].sweq !== "M" &&
                       !(input[id].snwd === "T" && input[id].sweq === "T")) {
                const depth = input[id].snwd === "T" ? 0.004 : Number.parseFloat(input[id].snwd, 10)
                const wteq = input[id].sweq === "T" ? 0.004 : Number.parseFloat(input[id].sweq, 10)
                if (wteq > depth) {
                    errorsForId.push("Snow depth must be greater than water equivalent.");
                    errorFieldsForId.push("snwd");
                    errorFieldsForId.push("sweq");
                } else if (wteq >= 0.004 && depth >= 0.004 && wteq/depth > 0.4) {
                    warnings += "Suspicious value - water:snow ratio is rather high for " + input[id].nwsli + ".";
                    errorFieldsForId.push("snwd");
                    errorFieldsForId.push("sweq");
                };
            }

            if (errorsForId.length) {
                errors = {...errors, [id]:errorsForId};
            }
            if (errorFieldsForId.length) {
                errorFields = {...errorFields, [id]:errorFieldsForId};
            }
        }
    });
    return {errors:errors, warnings:warnings, errorFields:errorFields};
};
