import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import ListingEntry from './ListingEntry';
//import ListingEntryRange from './ListingEntryRange';
import ListingResults from './ListingResults';
import PdfTool from './PdfTool';
import MovieTool from './MovieTool';

function UserTools(props) {
	const { moreToolsIndex, userEntryData } = props;
	const [ listEntryStatus, setListEntryStatus ] = useState(true);
	const [ listEntryRangeStatus, setListEntryRangeStatus ] = useState(true);
	const [ listResultsStatus, setListResultsStatus ] = useState(false);
	const [ listEntryData, setListEntryData ] = useState();

	const listHeaders = [
        {displayName: "nwsli", id: "nwsli"},
        {displayName: "obsdate", id: "formattedDate"},
        {displayName: "obstime", id: "formattedTime"},
        {displayName: "creation", id: "currentDt"},
        {displayName: "latitude", id: "lat"},
        {displayName: "longitude", id: "lon"},
        {displayName: "swe", id: "formattedsweq"},
        {displayName: "depth", id: "formattedsnwd"},
        {displayName: "comments", id: "notes"}
    ];

	const mapHeaders = [
		{displayName: "nwsli", id: "nwsli"},
		{displayName: "latitude", id: "lat"},
		{displayName: "longitude", id: "lon"},
		{displayName: "swe", id: "formattedsweq"},
	];

	const mapEntryData = {...userEntryData, coopid: "*", weekChecked: true}

	const updateListEntryStatus = (status) => {
		setListEntryStatus(status);
	};

	const updateListEntryRangeStatus = (status) => {
		setListEntryRangeStatus(status);
	};

	const updateListResultsStatus = (status) => {
		setListResultsStatus(status);
	}

	const updateListEntryData = (coopid, obsdate, weekChecked) => {
		var dtdate;
		if (typeof obsdate === 'string') {
			const ymd = obsdate.split("-");
			dtdate = new Date(ymd[0],parseInt(ymd[1])-1,ymd[2]);
		} else {
			const sdate = new Date(obsdate.startdate);
			const edate = new Date(obsdate.enddate);
			dtdate = [sdate, edate]
		}
		setListEntryData({coopid, obsdate:dtdate, weekChecked});
		setListResultsStatus(true);
    };

	useEffect(() => {
		updateListEntryStatus(true);
		updateListResultsStatus(false);
		updateListEntryRangeStatus(true);
	}, [moreToolsIndex]);

	return (
		<>
			<Typography variant="h5" component="h2" style={{marginBottom: "1em"}}>
				{moreToolsIndex}
			</Typography>
			{(moreToolsIndex === 'Data listing' || moreToolsIndex === 'Mapping file') &&
				<>
					{ listEntryStatus &&
						<ListingEntry 
							updateListEntryStatus={updateListEntryStatus}
							updateListEntryData={updateListEntryData}
							userEntryData={moreToolsIndex === 'Mapping file' ? mapEntryData : userEntryData}
						/>
					}
					{ listResultsStatus &&
						<ListingResults
							updateListEntryStatus={updateListEntryStatus}
							updateListResultsStatus={updateListResultsStatus}
							listEntryData={listEntryData}
							headers={moreToolsIndex === 'Mapping file' ? mapHeaders : listHeaders}
							saveLastDate={moreToolsIndex === 'Mapping file'}
						/>
					}
				</>
			}

			{moreToolsIndex === 'Contact information' &&
				<Typography>
					E-mail: <a href = "mailto:nrcc@cornell.edu">nrcc@cornell.edu</a>
				</Typography>
			}
			{moreToolsIndex === 'Data entry instructions' &&
				<>
					<PdfTool
						moreToolsIndex={moreToolsIndex}
					/>
					<MovieTool 
						moreToolsIndex={moreToolsIndex}
					/>
				</>
			}

		</>
	);
}
export default UserTools;

/* REMOVED SERACH BY CREATION DATE - 2023-12-01 KLE
{(moreToolsIndex === 'Search by creation date') &&
<>
	{ listEntryRangeStatus &&
		<ListingEntryRange
			updateListEntryRangeStatus={updateListEntryRangeStatus}
			updateListEntryData={updateListEntryData}
			userEntryData={userEntryData}
		/>
	}
	{ listResultsStatus &&
		<ListingResults
			updateListEntryStatus={updateListEntryRangeStatus}
			updateListResultsStatus={updateListResultsStatus}
			listEntryData={listEntryData}
			headers={listHeaders}
			saveLastDate={false}
		/>
	}
</>
}
*/
