import React, { useState } from 'react';
import { Button } from '@mui/material';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import ReactPlayer from 'react-player/file';
import instructionsVideo from '../videos/dataEntryInstructions_video.mp4';


function MovieTool(props) {
    const { moreToolsIndex } = props;
    const [showMovie, setShowMovie] = useState(false);
    const  movieFile = moreToolsIndex === "Data entry instructions" ? instructionsVideo : null;

    const handleClick = () => {
        setShowMovie((previous) => !previous);
    };

    return (
        <div>

            <Button startIcon={<LocalMoviesIcon />} color="primary" variant="contained" onClick={handleClick}>
                {showMovie ? "Hide video" : "View instructional video"}
            </Button>

            {showMovie &&
                <ReactPlayer 
                    url={movieFile}
                    height='50%'
                    width='100%'
                    controls={true}
                    style={{display:"inline-block",marginBottom:"2em"}}
                />
            }
        </div>
    );
}
export default MovieTool;