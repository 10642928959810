// download a file
const downloadFile = (href, filename) => {
	const link = document.createElement('a');
	link.href = href;
	link.download = filename;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

// setup json for download
const downloadJson = (response, filename) => {
    var blob;
	var jsonForMap = response.map((data) => {
		var sweq;
		if (data.sweq === "") {
			sweq = null;
		} else if (data.sweq === "T") {
			sweq = 0.004;
		} else {
			sweq = parseFloat(data.sweq);
		}
		return {
			nwsli: data.nwsli, 
			name: data.name, 
			sweq: sweq,
			lat: parseFloat(data.lat), 
			lon: parseFloat(data.lon),
			date: data.formattedDate
		};
	});
    const json = JSON.stringify(jsonForMap);
    blob = new Blob([json], {type:'application/json'});
    const href = window.URL.createObjectURL(blob);
    downloadFile(href, filename);
};

export default downloadJson;
