import React from 'react';
import { Button , Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function WarningDialog(props) {
  const { warningTitle, warningText, buttonLabels, processEnteredData, open, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  };
  const handleDont = () => {
    setOpen(false);
  };
  const handleDo = () => {
    processEnteredData();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="warning-title"
        aria-describedby="warning-description"
      >
        <DialogTitle id="warning-title">
          {warningTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="warning-description">
            {warningText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDont}>{buttonLabels[0]}</Button>
          <Button onClick={handleDo} autoFocus>{buttonLabels[1]}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default WarningDialog;