import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import CsvDownloader from 'react-csv-downloader';
import useFilterData from './useFilterData';
import downloadJson from './downloadJson';
import { client } from './client';
import { ALL_REPORTS_FOR_WEEK, ALL_REPORTS_FOR_DATE, GROUP_REPORTS_FOR_WEEK, GROUP_REPORTS_FOR_DATE } from './queries';

function ListingResults(props) {
	const { updateListEntryStatus, updateListResultsStatus, listEntryData, headers, saveLastDate } = props;
    const { coopid, obsdate, weekChecked } = listEntryData;
    const [ reportsData, setReportsData ] = useState([]);
    const [ rawData, setRawData ] = useState();
    const [ allLoading, setAllLoading ] = useState(false);
    
    const padStr = (val) => val.toString().padStart(2, "0");    //zero-pad a two-digit string representation of a number

    const crnt = new Date();
    const crntDateUTCString = crnt.getUTCFullYear().toString().substring(2) + padStr(crnt.getUTCMonth()+1) + padStr(crnt.getUTCDate()) + padStr(crnt.getUTCHours()) + padStr(crnt.getUTCMinutes());
    const filename = 'snowsurvey_export_' + crntDateUTCString;  //.csv added by CsvDownloader

    const wkfirst = weekChecked ? new Date(obsdate.getTime() - (obsdate.getDay() * 24 * 60 * 60 * 1000)) : obsdate;
    const wklast = weekChecked ? new Date(obsdate.getTime() + ((6-obsdate.getDay()) * 24 * 60 * 60 * 1000)) : obsdate;
    const reportForString = wkfirst.getFullYear() + "-" + (wkfirst.getMonth()+1) + "-" + wkfirst.getDate() +
          (weekChecked ? " to " + wklast.getFullYear() + "-" + (wklast.getMonth()+1) + "-" + wklast.getDate() : "");
    const caption =  (coopid === "*" ? "All" : coopid) + " reports " + (obsdate instanceof Date ? "for" : "created") + " " + reportForString;

    // filter out desired reports and store them in ReportsData
    useFilterData(rawData, saveLastDate, setReportsData);

    // get all reports for week or day
	const getAllReports = async() => {
		var q;
		setAllLoading(true);
		try {
            // setup and execute the query
            if (coopid === "*") {
                q = weekChecked ? ALL_REPORTS_FOR_WEEK(obsdate) : ALL_REPORTS_FOR_DATE(obsdate);
            } else {
                q = weekChecked ? GROUP_REPORTS_FOR_WEEK(coopid, obsdate) : GROUP_REPORTS_FOR_DATE(coopid, obsdate);
            }
			const data = await client.query(q);
			// save data to display 
            setRawData(data.data.data)
		} catch (error) {
			console.log(error);
		}
		setAllLoading(false);
	};

	const handleReturnClick = () => {	
        updateListResultsStatus(false);
		updateListEntryStatus(true);
	};

    const handleDownJsonClick = () => {
		downloadJson(reportsData, "SSx-22_sweq.json");
	};

    useEffect(() => {
        if (coopid) {
            getAllReports();
        }
    }, [coopid]);

	return (
		<>
            {!reportsData && allLoading && 
				<Typography variant="body1" color="error" gutterBottom>
					Loading data from database ...
				</Typography>	
			}

            {reportsData &&
                <>
                    <Typography variant="subtitle2" gutterBottom>
                        {caption}
                    </Typography>

                    {headers.map((head, j) => (
                        <Typography key={head.id} display="inline">
                            {j!==0?",":""}{head.displayName}
                        </Typography>
                    ))}
                    <br />

                    {reportsData.map((value, i) => (
                        <React.Fragment key={i}>
                            {headers.map((head, j) => (
                                <Typography key={i+head.id} display="inline">
                                    {j!==0?",":""}{value[head.id]}
                                </Typography>
                            ))}
                            <br />
                        </React.Fragment>
                    ))}

                    <Button variant="contained" color="primary" onClick={handleReturnClick}>
                        Return
                    </Button>
                    <Button variant="contained"  color="primary" onClick={handleDownJsonClick}>
                        Export to JSON file
                    </Button>
                    <CsvDownloader datas={reportsData} columns={headers} filename={filename} style={{display:"inline-flex"}}>
                        <Button variant="contained" color="primary">
                            Export to CSV file
                        </Button>
                    </CsvDownloader>
                </>
            }
		</>
	);
};
export default ListingResults;
