import React, { useState } from 'react';
import { Container, Paper, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import UserEntry from './UserEntry';
import UserInput from './UserInput';
import MoreTools from './MoreTools';
import UserTools from './UserTools';

const useStyles = makeStyles((theme) => ({
    rightAlign: {
        textAlign: "right",
		marginTop: "-10px",
    }
}));

function App() {
	const classes = useStyles();
	const [ showEntry, setShowEntry ] = useState(true);
	const [ showInput, setShowInput ] = useState(false);
	const [ showTools, setShowTools ] = useState(false);
	const [ userEntryData, setUserEntryData ] = useState({});
	const [ moreToolsIndex, setMoreToolsIndex ] = useState();

	const updateUserEntryStatus = (status) => {
		setShowEntry(status);
	};

	const updateUserInputStatus = (status) => {
		setShowInput(status);
	};

    const updateUserToolsStatus = (status) => {
		setShowTools(status);
	};

	const updateUserEntryData = (coopid, ids, obsdate, weekChecked) => {
		const ymd = obsdate.split("-");
		const dtdate = new Date(ymd[0],parseInt(ymd[1])-1,ymd[2]);
		setUserEntryData({coopid, ids, obsdate:dtdate, weekChecked});
	};

	const updateSelectedIndex = (value) => {
		setMoreToolsIndex(value);
        updateUserInputStatus(false);
		if (value === "Data entry") {
			updateUserToolsStatus(false);
			updateUserEntryStatus(true);
		} else {
			updateUserEntryStatus(false);
			updateUserToolsStatus(true);
		}
	};

	return (
		<Container>
			<Paper>
				<Grid container spacing={2}>
					<Grid item xs={2}></Grid>
					<Grid item xs={8}>
						<Typography variant="h4" component="h1" gutterBottom >
							Snow Survey {showTools ? "Tools" : "Data Entry"}
						</Typography>
					</Grid>
					<Grid item xs={2} className={classes.rightAlign}>
						<MoreTools 
							updateSelectedIndex={updateSelectedIndex}
						/>
					</Grid>
				</Grid>
				{ showEntry && 
					<UserEntry 
						updateUserEntryStatus={updateUserEntryStatus}
						updateUserInputStatus={updateUserInputStatus}
						updateUserEntryData={updateUserEntryData}
					/> 
				}
				{ showInput && 
					<UserInput 
						updateUserEntryStatus={updateUserEntryStatus}
						updateUserInputStatus={updateUserInputStatus}
						userEntryData={userEntryData}
					/> 
				}
				{ showTools &&
					<UserTools
						moreToolsIndex={moreToolsIndex}
						userEntryData={userEntryData}
					/>
				}					      
			</Paper>
		</Container>
	);
}
export default App;
