import { useEffect } from 'react';
import sitemeta from '../json/site-meta.json';    
 
const useFilterData = (data, saveLastDate, setReportsData) => {
    useEffect(() => {
        if (data) {
            // zero-pad a two-digit string representation of a number
            const padStr = (val) => val.toString().padStart(2, "0"); 

            // convert current date to yyyymmddhhnn string
            const crnt = new Date();
            const crntDateUTCString = "DC" + crnt.getUTCFullYear().toString().substring(2) + padStr(crnt.getUTCMonth()+1) + padStr(crnt.getUTCDate()) + padStr(crnt.getUTCHours()) + padStr(crnt.getUTCMinutes());

            const allReports = [];
            const lastDate = {};
            
            // filter desired records 
            data.forEach((entry) => {
                const formattedsweq = ["T",""].includes(entry.sweq) ? entry.sweq : parseFloat(entry.sweq).toFixed(1);
                const formattedsnwd = ["T",""].includes(entry.snwd) ? entry.snwd : Math.round(parseFloat(entry.snwd));
                const formattedDate = entry.year.substring(2) + entry.month.padStart(2, "0") + entry.day.padStart(2, "0");
                const formattedTime = entry.hour.padStart(2, "0") + "00";
                allReports.push({
                    ...entry, 
                    lat: entry.nwsli in sitemeta ? parseFloat(sitemeta[entry.nwsli].lat).toFixed(4) : "", 
                    lon: entry.nwsli in sitemeta ? parseFloat(sitemeta[entry.nwsli].lon).toFixed(4) : "",
                    formattedsweq: formattedsweq,
                    formattedsnwd: formattedsnwd,
                    formattedDate: formattedDate,
                    formattedTime: formattedTime,
                    currentDt: crntDateUTCString,
                });
                // retain latest date for a station
                if (!lastDate.hasOwnProperty(entry.nwsli) || formattedDate > lastDate[entry.nwsli]) {
                    lastDate[entry.nwsli] = formattedDate;
                }
            });
            setReportsData(allReports.filter((report) => !saveLastDate || report.formattedDate === lastDate[report.nwsli]))
        }
    }, [data]);
};

export default useFilterData;