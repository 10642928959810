import React, { useState } from 'react';
import { Button, Drawer, Box, List, ListItem, ListItemText } from '@mui/material';

function MoreTools(props) {
    const { updateSelectedIndex } = props;
	const [ drawerOpen, setDrawerOpen ] = useState(false);
    const [ selectedIndex, setSelectedIndex ] = useState(0);

	const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
		  return;
		}
		setDrawerOpen( open );
	};

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        updateSelectedIndex(listSelections[index]);
    };

    const listSelections = [
        'Data entry', 
        'Data listing', 
        'Mapping file',
//        'Search by creation date',
        'Data entry instructions',
        'Contact information',
    ];
    
	const list = () => (
		<Box
		  role="presentation"
		  onClick={toggleDrawer(false)}
		  onKeyDown={toggleDrawer(false)}
		>
		  <List>
			{listSelections.map((text, index) => (
                <ListItem
                    key={index}
                    button
                    selected={selectedIndex === index}
                    disabled={selectedIndex === index}
                    onClick={(event) => handleListItemClick(event, index)}
                >
				    <ListItemText primary={text} />
                </ListItem>
			))}
		  </List>
		</Box>
	);

	return (
        <>
            <Button variant="outlined" size="small" color="primary" onClick={toggleDrawer(true)}>More tools</Button>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                {list()}
            </Drawer>
        </>
	);
}
export default MoreTools;