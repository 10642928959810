import React, { useState } from 'react';
import { Button } from '@mui/material';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import instructionsPdf from '../pdfs//dataEntryInstructions.pdf'

import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

function PdfTool(props) {
    const { moreToolsIndex } = props;
    const [ showPdf, setShowPdf ] = useState(false);
    const pdfFile = moreToolsIndex === "Data entry instructions" ? instructionsPdf : null;

    const handleClick = () => {
        setShowPdf((previous) => !previous);
    };

    return (
        <>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Button startIcon={<PictureAsPdf />} color="primary" variant="contained" onClick={handleClick}>
                {showPdf ? "Hide pdf" : "View data entry instructions (pdf)"}
            </Button>
            {showPdf &&
                <div style={{textAlign:"cemter"}}>
                    <Viewer fileUrl={pdfFile} />
                </div>
            }
        </Worker>
        </>
    );
}
export default PdfTool;
