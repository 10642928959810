import React from 'react';
import { Button, Typography } from '@mui/material';

function UserConfirm(props) {
	const { updateUserInputStatus, updateUserEntryStatus, enteredData } = props;

	const handleClick = () => {	
        updateUserInputStatus(false);
        updateUserEntryStatus(true);
	};

	return (
		<> 
            <Typography variant="subtitle2" gutterBottom>
                Confirmation of entered data
            </Typography>

            {Object.keys(enteredData).map((i) => (
                (enteredData[i].snwd.length || enteredData[i].sweq.length || enteredData[i].notes.length) 
                ?
                    <Typography key={i}>
                        {enteredData[i].nwsli}, {enteredData[i].altid}, {enteredData[i].name}, {enteredData[i].year}, {enteredData[i].month}, {enteredData[i].day}, {enteredData[i].hour}, {enteredData[i].snwd}, {enteredData[i].sweq}, {enteredData[i].notes}, {enteredData[i].group}, {enteredData[i].creationDate}
                    </Typography>
                :
                    null
            ))}
            <Button variant="contained" color="primary" onClick={handleClick}>
                Return to start
            </Button>
        </>

	);
};
export default UserConfirm;
