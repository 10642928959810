import React, { useState } from 'react';
import { Button, TextField, Grid, Typography, FormControlLabel, Checkbox } from '@mui/material';
import sitelist from '../json/site-list.json';
import { isValidDate } from './validate';
import useFocus from './useFocus';

function UserEntry(props) {
	const { updateUserEntryStatus, updateUserInputStatus, updateUserEntryData } = props;
	const [ coopid, setCoopid ] = useState("");
	const [ obsdate, setObsdate ] = useState("");
	const [ showError, setShowError ] = useState(false);
	const [ weekChecked, setWeekChecked ] = useState(false);
	const [ coopidRef, setCoopidFocus ] = useFocus();
	const [ obsdateRef, setObsdateFocus ] = useFocus();

	const handleIdChange = (event) => {
		const coopid = event.target.value.toUpperCase();
		setCoopid(coopid);
		setShowError(false); //clear error message when user starts to enter new value
	};

	const handleDateChange = (event) => {
		const obsdate = event.target.value;
		setObsdate(obsdate);
		setShowError(false); //clear error message when user starts to enter new value
	};

	const handleCheckboxChange = (event) => {
		setWeekChecked(event.target.checked);
	};

	const handleClick = () => {
		const ids = sitelist[coopid] || [];
		if (!ids.length) {
			setShowError("There is no site list for this cooperator id; please enter a different id.");
			setCoopid("");
			setCoopidFocus();
		} else if (!isValidDate(obsdate)) {
			setShowError("Invalid date");
			setObsdate("");
			setObsdateFocus();
		} else {
			setShowError(false);
			updateUserEntryStatus(false);
			updateUserInputStatus(true);
			updateUserEntryData(coopid, ids, obsdate, weekChecked);
		};
	};
	
	return (
		<>
			<form noValidate autoComplete="off">
				<Grid container direction="column" alignItems="center">
					<Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={3}>
						<Grid item>
							<TextField 
								required 
								autoFocus
								variant="outlined"
								value={coopid} 
								name="coopid"
								label="Cooperator ID" 
								onChange={handleIdChange}
								inputRef={coopidRef}
								onFocus={(event) => event.target.select()}
							/>
						</Grid>
						<Grid item>
							<TextField 
								required 
								variant="outlined"
								value={obsdate} 
								name="obsdate"
								label="Observation date" 
								onChange={handleDateChange}
								inputRef={obsdateRef}
								onFocus={(event) => event.target.select()}
								placeholder="yyyy-mm-dd"
								helperText="Can be changed for individual observations"
							/>
						</Grid>
					</Grid>

					<Grid item>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									checked={weekChecked}
									onChange={handleCheckboxChange}
								/>
							}
							label="Show all reports for week"
						/>
					</Grid>

					<Grid item>
						{showError &&
							<Typography variant="body2" color="error" mt={1.5}>
								{showError}
							</Typography>
						}
					</Grid>
					
					<Grid item>
						<Button variant="contained" color="primary" onClick={handleClick}>
							Continue
						</Button>
					</Grid>
				</Grid>
			</form>
		</>
	);
}
export default UserEntry;
