import { fql } from "fauna";
import { weekNumber } from './weekNumber';

export const ALL_REPORTS = fql`SnowSurvey.all() {
  id,
  nwsli,
  altid,
  name,
  year,
  month,
  day,
  week,
  hour,
  snwd,
  sweq,
  notes,
  creationDate,
  group
}`;

export function ALL_REPORTS_FOR_WEEK(obsdate) {
  const rweek = weekNumber(obsdate.getFullYear(), obsdate.getMonth()+1, obsdate.getDate());
  return fql`SnowSurvey.allReportsForWeek(${rweek}, { from: "000", to: "ZZZ"}).paginate(2000)`;
};

export function ALL_REPORTS_FOR_DATE(obsdate) {
  const ryear = obsdate.getFullYear();
  const rmonth = obsdate.getMonth()+1;
  const rday = obsdate.getDate();
  const rweek = weekNumber(ryear, rmonth, rday);
  return fql`SnowSurvey.allReportsForWeek(${rweek}, { from: "000", to: "ZZZ"}).where(.year == ${ryear}.toString()).where(.month == ${rmonth}.toString()).where(.day == ${rday}.toString()).paginate(2000)`;
};

export function GROUP_REPORTS_FOR_WEEK(rgroup, obsdate) {
  const rweek = weekNumber(obsdate.getFullYear(), obsdate.getMonth()+1, obsdate.getDate());
  return fql`SnowSurvey.groupReportsForWeek(${rgroup}, { from: ${rweek}, to: ${rweek} }).paginate(1000)`;
};

export function GROUP_REPORTS_FOR_DATE(rgroup, obsdate) {
  const ryear = obsdate.getFullYear();
  const rmonth = obsdate.getMonth()+1;
  const rday = obsdate.getDate();
  const rweek = weekNumber(ryear, rmonth, rday);
  return fql`SnowSurvey.groupReportsForWeek(${rgroup}, { from: ${rweek}, to: ${rweek} }).where(.year == ${ryear}.toString()).where(.month == ${rmonth}.toString()).where(.day == ${rday}.toString()).paginate(1000)`;
};

export function SITE_REPORTS_FOR_WEEK(rnwsli, obsdate) {
  const rweek = weekNumber(obsdate.getFullYear(), obsdate.getMonth()+1, obsdate.getDate());
  return fql`SnowSurvey.siteReportsForWeek(${rnwsli}, { from: ${rweek}, to: ${rweek} }).paginate(100)`;
};

export function SITE_REPORTS_FOR_DATE(rnwsli, obsdate) {
  const ryear = obsdate.getFullYear();
  const rmonth = obsdate.getMonth()+1;
  const rday = obsdate.getDate();
  const rweek = weekNumber(ryear, rmonth, rday);
  return fql`SnowSurvey.siteReportsForWeek(${rnwsli}, { from: ${rweek}, to: ${rweek} }).where(.year == ${ryear}.toString()).where(.month == ${rmonth}.toString()).where(.day == ${rday}.toString()).paginate(100)`;
};

export function CREATE_REPORT(data) {
  return fql`SnowSurvey.create(${data}) {id}`;
};

export function DELETE_REPORT(id) {
  return fql`SnowSurvey.byId(${id})!.delete()`;
};

export function UPDATE_REPORT(id, data) {
  return fql`SnowSurvey.byId(${id})!.update(${data})`;
};


/*
import { gql } from "@apollo/client";
export const CREATE_REPORT = gql`
 mutation CreateReport($data: SnowSurveyInput!) {
   createSnowSurvey(data: $data) {
     _id
   }
 }
`;

export const DELETE_REPORT = gql`    
  mutation DeleteReport($id: ID!) {
    deleteSnowSurvey( id: $id ) {
      nwsli
      name
    }
  }
`;

export const UPDATE_REPORT = gql`    
  mutation UpdateReport($id: ID!, $data: SnowSurveyInput!) {
    updateSnowSurvey( id: $id, data: $data) {
      _id
    }
  }
`;

export const ALL_REPORTS = gql`
 {
   allReports(_size:20000) {
     data {
       _id
       nwsli
       altid
       name
       year
       month
       day
       week
       hour
       snwd
       sweq
       notes
       creationDate
       group
     }
   }
 }
`;

export const GROUP_REPORTS = gql`
 query groupReports($group: String!) {
   groupReports(group:$group, _size:4000) {
    data {
        _id
        nwsli
        altid
        name
        year
        month
        day
        week
        hour
        snwd
        sweq
        notes
        creationDate
        group
    }
   }
 }
`;

export const ALL_REPORTS_FOR_DATE = gql`
 query allReportsForDate($year: String!, $month: String!, $day: String!) {
   allReportsForDate(year:$year, month:$month, day:$day, _size:4000) {
    data {
        _id
        nwsli
        altid
        name
        year
        month
        day
        week
        hour
        snwd
        sweq
        notes
        creationDate
        group
    }
   }
 }
`;

export const ALL_REPORTS_FOR_WEEK = gql`
 query allReportsForWeek($week: String!) {
   allReportsForWeek(week:$week, _size:4000) {
    data {
        _id
        nwsli
        altid
        name
        year
        month
        day
        week
        hour
        snwd
        sweq
        notes
        creationDate
        group
    }
   }
 }
`;

export const GROUP_REPORTS_FOR_DATE = gql`
 query groupReportsForDate($year: String!, $month: String!, $day: String!, $group: String!) {
   groupReportsForDate(year:$year, month:$month, day:$day, group:$group, _size:4000) {
    data {
        _id
        nwsli
        altid
        name
        year
        month
        day
        week
        hour
        snwd
        sweq
        notes
        creationDate
        group
    }
   }
 }
`;

export const GROUP_REPORTS_FOR_WEEK = gql`
 query groupReportsForWeek($week: String!, $group: String!) {
   groupReportsForWeek(week:$week, group:$group, _size:4000) {
    data {
        _id
        nwsli
        altid
        name
        year
        month
        day
        week
        hour
        snwd
        sweq
        notes
        creationDate
        group
    }
   }
 }
`;

export const SITE_REPORTS_FOR_DATE = gql`
 query siteReportsForDate($year: String!, $month: String!, $day: String!, $nwsli: String!) {
   siteReportsForDate(year:$year, month:$month, day:$day, nwsli:$nwsli, _size:4000) {
     data {
        _id
        nwsli
        altid
        name
        year
        month
        day
        week
        hour
        snwd
        sweq
        notes
        creationDate
        group
     }
   }
 }
`;

*/
