import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import WarningDialog from './WarningDialog'; 
import UserConfirm from './UserConfirm';
import { validateEnteredData } from './validate';
import { weekNumber } from './weekNumber';
import sitemeta from '../json/site-meta.json';
import { client } from './client';
import { GROUP_REPORTS_FOR_WEEK, GROUP_REPORTS_FOR_DATE, SITE_REPORTS_FOR_DATE, CREATE_REPORT, DELETE_REPORT, UPDATE_REPORT } from './queries';

function UserInput(props) {
	const { updateUserInputStatus, updateUserEntryStatus, userEntryData } = props;
	const { coopid, ids, obsdate, weekChecked } = userEntryData;	//coopid is cooperator id
	const [ enteredData, setEnteredData ] = useState();
	const [ entryErrors, setEntryErrors ] = useState({});
	const [ entryWarnings, setEntryWarnings ] = useState({});
	const [ errorFields, setErrorFields ] = useState({});
	const [ dialogOpen, setDialogOpen ] = useState(false);
	const [ showConfirm, setShowConfirm ] = useState(false);
	const [ filterChecked, setFilterChecked ] = useState(false);
	const [ filteredKeys, setFilteredKeys ] = useState([]);
	const [ allLoading, setAllLoading ] = useState(false);
	const [ createLoading, setCreateLoading ] = useState(false);
	const [ deleteLoading, setDeleteLoading ] = useState(false);
	const [ updateLoading, setUpdateLoading ] = useState(false);
	const entryDate = new Date().toISOString().substring(0, 16);

	// default data for new entry
	const defaultEntry = {
		year:obsdate.getFullYear().toString(), 
		month:(obsdate.getMonth()+1).toString(), 
		day:obsdate.getDate().toString(), 
		hour:"12", 
		snwd:"", 
		sweq:"",
		notes:"",
		creationDate:"",
	};

	// get all reports for week or day
	const getGroupReports = async() => {
		var q;
		setAllLoading(true);
		// setup and execute the query
		if (weekChecked) {
			q = GROUP_REPORTS_FOR_WEEK(coopid, obsdate);
		} else {
			q = GROUP_REPORTS_FOR_DATE(coopid, obsdate);
		}
		try {
			const data = await client.query(q);
			// add existing data to page and also save in existingData element of enteredData object
			data.data.data.forEach((entry) => {
				const entryDate = new Date(entry.year,entry.month-1,entry.day);
				if (ids.includes(entry.nwsli)) {
					let ymd = entry.year + entry.month.padStart(2, "0") + entry.day.padStart(2, "0");
					setEnteredData((prevState) => ({...prevState, [entry.nwsli+"_"+ymd]:{...entry, existingData:entry}}));
				}
			});
		} catch (error) {
			console.log(error);
		}
		setAllLoading(false);
	};

	// add report
	const createReport = async(data) => {
		setCreateLoading(true);
		// setup and execute the query
		try {
			const q = CREATE_REPORT(data);
			const result = await client.query(q);
			//console.log(result.data.id)
		} catch (error) {
			console.log(error);
		}
		setCreateLoading(false);
	};

	// delete report
	const deleteReport = async(id) => {
		setDeleteLoading(true);
		// setup and execute the query
		try {
			const q = DELETE_REPORT(id);
			const result = await client.query(q);
			//console.log(result)
		} catch (error) {
			console.log(error);
		}
		setDeleteLoading(false);
	};

	// update report
	const updateReport = async(id, data) => {
		setUpdateLoading(true);
		// setup and execute the query
		try {
			const q = UPDATE_REPORT(id, data);
			const result = await client.query(q);
			//console.log(result)
		} catch (error) {
			console.log(error);
		}
		setUpdateLoading(false);
	};

	// if no existing data for station/date, create new database record; if existing data, display alert (don't save to database)
	const newReport = async(key, stndata) => {
		try {
			const { nwsli, year, month, day } = stndata;
			const rdate = new Date(parseInt(year),parseInt(month)-1,parseInt(day));
			const q = SITE_REPORTS_FOR_DATE(nwsli, rdate);
			const data = await client.query(q);
			const isDuplicate = data.data.data.length > 0;
			if (isDuplicate) {
				// don't overwrite existing data
				alert('Ignoring entry for ' + nwsli + " " + [year,month,day].join("-") + 
					': duplicate report - ' + JSON.stringify(data.data.data, ['creationDate','group','hour','snwd','sweq','notes'], 1));
				// remove entered data from page
				setEnteredData((prevState) => {
					const newData = {...prevState}
					delete newData[key]
					return newData;
				});
			} else {
				// add data to database
				const week = weekNumber(parseInt(year), parseInt(month), parseInt(day));
				createReport({...stndata, week:week, creationDate:entryDate});
				setEnteredData((prevState) => ({...prevState, [key]:{...prevState[key], creationDate:entryDate}}));
			}
		} catch (error) {
			console.log(error);
		}
	};

	// create/delete/update data in database as necessary, then show confirmation page
	const processEnteredData = () => {
		for (const [key,value] of Object.entries(enteredData)) {
			if (!value.id) {
				// new record
				if (value.snwd.length || value.sweq.length || value.notes.length) {
					// add to database, if there isn't an existing record for that date
					newReport(key, value);
				}
			} else if (!value.snwd.length && !value.sweq.length && !value.notes.length) {
				// user manually deleted all values, so delete entry from database
				deleteReport(value.id);				
			} else {
				// check is any data values have changed in existing record
				let updates = {};
				['snwd', 'sweq', 'notes', 'hour'].forEach((elem) => {
					if (value[elem] !== value.existingData[elem]) {
						updates = {...updates, [elem]:value[elem]};
					}
				});
				if (Object.keys(updates).length) {
					// save to database and update page
					updateReport(value.id, {...updates, creationDate:entryDate});
					setEnteredData((prevState) => ({...prevState, [key]:{...prevState[key], creationDate:entryDate}}));
				}
			}
		}
		// hide input and show confirmation
		setShowConfirm(true);
	};

	// save key entered data values in state (will be added to database in processEnteredData)
	const updateEnteredData = (nwsli, event) => {
		const value = event.target.value;
		const name = event.target.name;
		setEnteredData((prevState) => ({...prevState, [nwsli]:{...prevState[nwsli], [name]: value}}));
	};

	// delete a station from database and reinitialize station on current page (removing old database id and existing data)
	const handleDelete = (id, e) => {
		e.preventDefault();
		deleteReport(enteredData[id].id);
		if (!weekChecked) {
			// reinitialize entry on current page for station to default
			const [nwsli, ] = id.split("_");
			setEnteredData((prevState) => ({...prevState, [id]:{ ...defaultEntry, nwsli:nwsli, altid:sitemeta[nwsli].altid, name:sitemeta[nwsli].name, group:coopid}}));
		} else {
			// remove entry from page
			setEnteredData((prevState) => {
				const newData = {...prevState}
				delete newData[id]
				return newData;
			});
		}
	};

	// submit entered data
	const handleContinueClick = () => {	
		const validation = validateEnteredData(enteredData);
		setEntryErrors(validation.errors);
		setEntryWarnings(validation.warnings);
		setErrorFields(validation.errorFields);
		if (Object.entries(validation.errors).length === 0 && validation.warnings.length === 0) {
			processEnteredData();
		} else if (Object.entries(validation.errors).length === 0 && validation.warnings.length > 0) {
			setDialogOpen(true);
		}
	};

	// cancel entered data
	const handleCancelClick = () => {	
		updateUserInputStatus(false);
		updateUserEntryStatus(true);
	};

	// handle filtering checkbox change
	const handleFilterChange = (event) => {
		const checked = event.target.checked;
		setFilterChecked(checked);
		// create list of most recent key for each site, with those having exising data taking precedence over empty entries
		if (checked) {
			const fkeys = [];
			const nwslis = {};
			// compile all dates for each nwsli
			Object.keys(enteredData).forEach((key) => {
				const [nwsli, ymd] = key.split("_");
				if (!Object.keys(nwslis).includes(nwsli)) {
					nwslis[nwsli] = [];
				} 
				nwslis[nwsli].push(ymd);
			});
			// pick out latest date (preferably with existing data) for each site and save reconstructed key
			Object.keys(nwslis).forEach((id) => {
				if (nwslis[id].length === 1) {
					const skey = id + "_" + nwslis[id][0];
					fkeys.push(skey)
				} else {
					let saved_key = id + "_" + nwslis[id][-1];
					nwslis[id].sort((a, b) => a > b ? 1 : -1).forEach((dt) => {
						let testkey = id + "_" + dt;
						if (enteredData[testkey].hasOwnProperty('existingData')) {
							saved_key = testkey;
						}
					});
					fkeys.push(saved_key);
				}
			});
			setFilteredKeys(fkeys);
		}
	};

	// sort enteredData keys by nwsli 
	const sortKeys = (keyArray) => {
		keyArray.sort((a, b) => a > b ? 1 : -1);  //sorts by nwsli and date
		return keyArray;
	};

	// set up default entries for each station and query database for exising data
	useEffect(() => {
		if (ids.length) {
			const defaultData = {};
			const ymd = defaultEntry.year + defaultEntry.month.padStart(2, "0") + defaultEntry.day.padStart(2, "0");
			ids.forEach((nwsli) => {
				defaultData[nwsli+"_"+ymd] = {
					...defaultEntry,
					nwsli:nwsli,
					altid:"altid" in sitemeta[nwsli] ? sitemeta[nwsli].altid : "",
					name:sitemeta[nwsli].name, 
					group:coopid,
				};
			});
			setEnteredData(defaultData);
			getGroupReports();
		}
	}, [ids]);	


	return (
		<>
			{!showConfirm && (!enteredData || allLoading) && 
				<Typography variant="body1" color="error" gutterBottom>
					Checking database for existing data ...
				</Typography>	
			}

			{enteredData && !showConfirm &&
				<>
					<Typography variant="subtitle2" gutterBottom>
						Cooperator ID: {coopid}
					</Typography>
					{weekChecked && 
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									checked={filterChecked}
									size="small"
									onChange={handleFilterChange}
								/>
							}
							label="Filter to one entry per site"
						/>
					}
					<form noValidate autoComplete="off">
						<TableContainer >
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell align="center">NWSLI</TableCell>
										<TableCell align="center">Alt ID</TableCell>
										<TableCell align="left">Site name</TableCell>
										<TableCell align="center">Year</TableCell>
										<TableCell align="center">Month</TableCell>
										<TableCell align="center">Day</TableCell>
										<TableCell align="center">Hour (UTC)</TableCell>
										<TableCell align="center">Depth (in)</TableCell>
										<TableCell align="center">SWE (in)</TableCell>
										<TableCell align="center">Notes</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{sortKeys(filterChecked ? filteredKeys : Object.keys(enteredData)).map((id) => 
										<React.Fragment key={id}>
											<TableRow>
												<TableCell align="center">{enteredData[id].nwsli}</TableCell>
												<TableCell align="center">{sitemeta[enteredData[id].nwsli].altid}</TableCell>
												<TableCell align="left">{sitemeta[enteredData[id].nwsli].name}</TableCell>
												<TableCell align="center">
													<TextField
														name="year"
														value={enteredData[id].year}
														error={errorFields[id] && errorFields[id].includes('year')}
														disabled={enteredData[id].hasOwnProperty("existingData")}
														onChange={(e) => updateEnteredData(id, e)}
														style={{width:"4em"}}
														inputProps={{ style: {padding:"6px"}, tabIndex: "-1" }}
													/>	
												</TableCell>
												<TableCell align="center">
													<TextField
														name="month"
														value={enteredData[id].month} 
														error={errorFields[id] && errorFields[id].includes('month')}
														disabled={enteredData[id].hasOwnProperty("existingData")}
														onChange={(e) => updateEnteredData(id, e)}
														style={{width:"2.5em"}}
														inputProps={{ style: {padding:"6px"}, tabIndex: "-1"  }}
													/>	
												</TableCell>
												<TableCell align="center">
													<TextField
														name="day"
														value={enteredData[id].day} 
														error={errorFields[id] && errorFields[id].includes('day')}
														disabled={enteredData[id].hasOwnProperty("existingData")}
														onChange={(e) => updateEnteredData(id, e)}
														style={{width:"2.5em"}}
														inputProps={{ style: {padding:"6px"}, tabIndex: "-1"  }}
													/>	
												</TableCell>
												<TableCell align="center">
													<TextField
														name="hour"
														value={enteredData[id].hour} 
														error={errorFields[id] && errorFields[id].includes('hour')}
														onChange={(e) => updateEnteredData(id, e)}
														style={{width:"2.5em"}}
														inputProps={{ style: {padding:"6px"}, tabIndex: "-1"  }}
													/>
												</TableCell>
												<TableCell align="center">
													<TextField
														name="snwd"
														value={enteredData[id].snwd} 
														error={errorFields[id] && errorFields[id].includes('snwd')}
														onChange={(e) => updateEnteredData(id, e)}
														style={{width:"4.5em"}}
														inputProps={{ style: {padding:"6px"} }}
													/>
												</TableCell>
												<TableCell align="center">
													<TextField
														name="sweq"
														value={enteredData[id].sweq} 
														error={errorFields[id] && errorFields[id].includes('sweq')}
														onChange={(e) => updateEnteredData(id, e)}
														style={{width:"4.5em"}}
														inputProps={{ style: {padding:"6px"} }}
													/>
												</TableCell>
												<TableCell align="left">
													<TextField
														name="notes"
														value={enteredData[id].notes} 
														onChange={(e) => updateEnteredData(id, e)}
														inputProps={{ style: {padding:"6px"} }}
													/>
												</TableCell>
												<TableCell align="center">
													{enteredData[id].id &&
														<Button variant="outlined" color="primary" size="small" disabled={deleteLoading} onClick={(e) => handleDelete(id, e)}>
															Delete
														</Button>
													}
												</TableCell>
											</TableRow>
											{entryErrors[id] && entryErrors[id].length > 0 &&
												<TableRow>
													<TableCell colSpan={10}>
														<Typography color="error">
															{entryErrors[id].reduce((prev, curr) => [ prev, ' ', curr ] )}
														</Typography>
													</TableCell>
												</TableRow>
											}
										</React.Fragment>
									)}
								</TableBody>
							</Table>
						</TableContainer>

						{ Object.entries(entryErrors).length > 0 &&
							<Typography variant="body2" color="error" style={{margin:"1em 0 0.2em 0"}}>
								Correct errors and press Continue
							</Typography>
						}

						{ dialogOpen &&
							<WarningDialog
								warningTitle={"Validation warning"}
								warningText={entryWarnings}
								buttonLabels={["Don't submit","Submit Anyway"]}
								processEnteredData={processEnteredData}
								open={dialogOpen}
								setOpen={setDialogOpen}
							/>
						}

						<Button variant="contained" color="primary" disabled={createLoading || updateLoading || deleteLoading} onClick={handleContinueClick}>
							Continue
						</Button>
						<Button variant="contained" color="primary" disabled={createLoading || updateLoading || deleteLoading} onClick={handleCancelClick}>
							Cancel
						</Button>
					</form>

				</>
			}
			{showConfirm &&
				<UserConfirm
					updateUserInputStatus={updateUserInputStatus}
					updateUserEntryStatus={updateUserEntryStatus}
					enteredData={enteredData}
				/> 
			}
		</>
	);
};
export default UserInput;
